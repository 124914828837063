import React from "react";
import { List } from "@refinedev/mui";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { ICategory } from "interfaces/category";
import { InfiniteList } from "components/list/infinity-list";
import { CardContent, Typography, IconButton } from "@mui/material";

export const CategoryList: React.FC = () => {
  const navigate = useNavigate();

  return (
    <List
      title="Categories List"
      wrapperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          padding: 0,
        },
      }}
    >
      <InfiniteList<ICategory>
        endpoint="categories"
        limit={5}
        renderItem={(category) => (
          <CardContent
            sx={{
              pl: 3,
              "&:last-child": { pb: 2 },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              {category.categoryName}
            </Typography>
            <IconButton
              onClick={() =>
                navigate(`/categories/${category.categoryId}/edit`)
              }
              edge="end"
            >
              <SettingsIcon />
            </IconButton>
          </CardContent>
        )}
        searchConfig={{
          enabled: true,
          placeholder: "Search investments...",
          searchField: "keyword",
        }}
      />
    </List>
  );
};
