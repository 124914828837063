import React from "react";
import { List, DateField } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { moneyFormat } from "utils/money-format";
import { InfiniteList } from "components/list/infinity-list";

export const ProcessedGrantVestmentRequestList: React.FC<
  IResourceComponentsProps
> = () => {
  return (
    <>
      <List title="Processed GrantVestment Request List">
        <InfiniteList<IGrantVestmentRequest>
          endpoint="grant-vestment-requests"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "grantVestmentRequestId",
              header: "ID",
            },
            {
              field: "grantVestment",
              header: "Grant vestment title",
              width: 200,
              renderCell: function render({ grantVestment }) {
                return grantVestment.name;
              },
            },
            {
              field: "fund",
              header: "Fund Name",
              width: 200,
              renderCell: function render({ fund }) {
                return fund.fundAccountName;
              },
            },
            {
              field: "user",
              header: "Advisor",
              renderCell: function render({ user }) {
                return `${user.firstName} ${user.lastName}`;
              },
            },
            {
              field: "appliedAmount",
              header: "Applied Amount",
              renderCell: function render({ appliedAmount }) {
                return <>${moneyFormat(appliedAmount)}</>;
              },
            },
            {
              field: "status",
              header: "Status",
            },
            {
              field: "createdAt",
              header: "Created Date",
              renderCell: function render({ createdAt }) {
                return <DateField value={createdAt} />;
              },
            },
            {
              field: "executionDate",
              header: "Accepting Date",
              renderCell: function render({ executionDate }) {
                return <DateField value={executionDate} />;
              },
            },
          ]}
          additionalFilters={[
            {
              field: "filter[status]",
              operator: "eq",
              value: "PROCESSED",
            },
            {
              field: "filter[transfer]",
              operator: "eq",
              value: "false",
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          emptyMessage={`No GrantVestment request found!`}
        />
      </List>
    </>
  );
};
