import {
  Box,
  CircularProgress,
  Card,
  CardContent,
  listItemClasses,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  Grid,
  Button,
  FormControl,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCustom, useUpdate } from "@refinedev/core";
import { InfiniteList } from "components/list/infinity-list";
import { IFundSnapshot } from "interfaces/fund-snapshot";
import { IFundSnapshotTotals } from "interfaces/IFundSnaphotTotals";
import { useEffect, useState } from "react";
import { warm, neutral, primary } from "style/color";
import { getNextDay, getPreviousDay, shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";

export const SnapshotCard: React.FC = () => {
  const [date, setDate] = useState<Date | null>(null);
  const [dateFilter, setDateFilter] = useState<string>(
    getPreviousDay(new Date())
  );
  const [snapshotsData, setSnapshotsData] = useState<IFundSnapshotTotals>();
  const { mutate, isLoading: recalculateIsLoading } = useUpdate();
  const {
    data: snapshotTotal,
    isLoading: isLoadingTotals,
    isSuccess,
    refetch,
  } = useCustom<IFundSnapshotTotals>({
    url: `fund-snapshot/total`,
    method: "get",
    config: {
      filters: [
        {
          field: "filter[date]",
          operator: "eq",
          value: dateFilter,
        },
      ],
    },
  });
  const { data: locked } = useCustom({
    url: `fund-snapshot/locked`,
    method: "get",
  });

  const handleClick = (event: any) => {
    mutate(
      {
        resource: `fund-snapshot/calculate`,
        values: {
          date: getNextDay(locked?.data.date as Date),
        },
        id: "",
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  useEffect(() => {
    if (snapshotTotal) {
      setSnapshotsData(snapshotTotal.data as IFundSnapshotTotals);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotTotal]);

  const handleChange = (tempDate: Date | null) => {
    if (tempDate) {
      setDate(tempDate);

      const day = `${tempDate.getDate()}-${tempDate.toLocaleDateString(
        "en-us",
        {
          month: "short",
        }
      )}-${tempDate.getUTCFullYear()}`;
      setDateFilter(day);
    } else {
      setDateFilter("");
    }
  };
  const getDate = () => {
    const nextDay = getNextDay(locked?.data.date as Date);
    return shortFormat(nextDay);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={4}>
            {locked && locked.data && (
              <Typography
                sx={{ color: neutral[600] }}
                variant="bodyCopyRegular"
              >
                Last Day Locked :{locked?.data.snapshotDay as string}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} textAlign={"center"}>
            {locked && locked.data && (
              <Button
                disabled={recalculateIsLoading}
                startIcon={
                  recalculateIsLoading && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
                onClick={handleClick}
                color="primary"
                variant="contained"
              >
                Recalculate for {getDate()}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={4} textAlign={"end"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl size="small" sx={{ py: 1.5 }}>
                <DatePicker
                  slotProps={{
                    textField: {
                      id: "date",
                      name: "date",
                    },
                  }}
                  label="Filter by date"
                  disableFuture={true}
                  format="MM/DD/YYYY"
                  name="dateOfBirth"
                  value={date}
                  onChange={(date) => handleChange(date && new Date(date))}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <TableContainer
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            mt: 2,
            [` & .${listItemClasses.root}:hover`]: {
              backgroundColor: warm[200],
              cursor: "pointer",
            },
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: "grey.200",
              }}
            >
              <TableRow>
                <TableCell sx={{ color: primary[500] }}> Total Funds</TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  {" "}
                  Total Investments
                </TableCell>
                <TableCell sx={{ color: primary[500] }}> Total Cash</TableCell>
                <TableCell sx={{ color: primary[500] }}>Total Liquid</TableCell>
                <TableCell sx={{ color: primary[500] }}>Total GV</TableCell>
                <TableCell sx={{ color: primary[500] }}>Tot AUM</TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  Total AUM Fee
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>Total GV Fee</TableCell>
              </TableRow>
              {isLoadingTotals && (
                <Grid item xs={12} textAlign="center" sx={{ m: 3 }}>
                  <CircularProgress color="primary" />
                </Grid>
              )}
              {isSuccess && snapshotsData && (
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {snapshotsData.fundCount}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalInvestments)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalCash)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalLiquid)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalGrantvestments)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalAum)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalAumFee)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    ${moneyFormat(snapshotsData.totalGrantvestmentFee)}
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>

        <Box>
          <InfiniteList<IFundSnapshot>
            endpoint="fund-snapshot"
            limit={10}
            isTable={true}
            columns={[
              {
                field: "snapshotDay",
                header: "Dates",
              },
              {
                header: "Fund Name",
                renderCell: function render({ fund }) {
                  return fund.fundAccountName;
                },
              },
              {
                header: "Investments",
                renderCell: function render({ investmentsTotal }) {
                  return `${moneyFormat(investmentsTotal)}`;
                },
              },
              {
                header: "Cash",
                renderCell: function render({ cash }) {
                  return `${moneyFormat(cash)}`;
                },
              },
              {
                header: "Total Liquid",
                renderCell: function render({ totalLiquid }) {
                  return `${moneyFormat(totalLiquid)}`;
                },
              },
              {
                header: "GV",
                renderCell: function render({ grantvestmentsTotal }) {
                  return `${moneyFormat(grantvestmentsTotal)}`;
                },
              },
              {
                header: "AUM",
                renderCell: function render({ aum }) {
                  return `${moneyFormat(aum)}`;
                },
              },
              {
                header: "AUM Fee",
                renderCell: function render({ aumFee }) {
                  return `${moneyFormat(aumFee)}`;
                },
              },
              {
                header: "GV Fee",
                renderCell: function render({ grantvestmentFee }) {
                  return `${moneyFormat(grantvestmentFee)}`;
                },
              },
            ]}
            tableProps={{
              stickyHeader: true,
              headerBgColor: "grey.200",
              responsive: true,
            }}
            additionalFilters={[
              {
                field: "filter[date]",
                operator: "eq",
                value: dateFilter,
              },
            ]}
            emptyMessage={`You don't have any snapshots.`}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
