import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  FormControl,
  FilledInput,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useCustom } from "@refinedev/core";
import { InView } from "react-intersection-observer";
import ClearIcon from "@mui/icons-material/Clear";
import { SelectMultipleCheckboxDropdown } from "components/inputs/SelectMultipleCheckboxDropdown";
import {
  INVESTMENT_FORMAT_ENUM,
  INVESTMENT_RISK_TYPE_ENUM,
} from "interfaces/investment";
import { get } from "react-hook-form";

export interface Option {
  key: number;
  label: string;
  value: string;
}

export interface PaginationInfo {
  hasNext: boolean;
  nextOffset?: number;
  offset: number;
}

export interface PaginatedEntityList<T> {
  data: T[];
  pagination: PaginationInfo;
}

export interface SearchConfig {
  enabled?: boolean;
  placeholder?: string;
  searchField: string;
}

interface TableColumn<T> {
  field?: keyof T | ((item: T) => React.ReactNode);
  header: string;
  width?: string | number;
  renderCell?: (item: T) => React.ReactNode;
}

interface InfiniteListProps<T> {
  endpoint: string;
  limit?: number;
  renderItem?: (item: T, index: number) => React.ReactNode;
  emptyMessage?: string;
  gridProps?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    spacing?: number;
  };
  queryParams?: Record<string, any>;
  searchConfig?: SearchConfig;
  dropDownSearchOption1?: Option[];
  dropDownSearchOption1Title?: string;
  dropDownSearchOption2Title?: string;
  dropDownSearchOption2?: Option[];
  onRefetchTrigger?: boolean;
  onRefetchComplete?: () => void;
  setIsLoading?: (loading: boolean) => void;
  tableHeader?: React.ReactNode;
  isTable?: boolean;
  additionalFilters?: Array<{
    field: string;
    operator: string;
    value: any;
  }>;
  columns?: TableColumn<T>[];
  tableProps?: {
    stickyHeader?: boolean;
    headerBgColor?: string;
    responsive?: boolean;
  };
}

export const InfiniteList = <T extends object>({
  endpoint,
  limit = 5,
  renderItem,
  emptyMessage = "No items found.",
  gridProps = { xs: 12, spacing: 2 },
  queryParams = {},
  searchConfig = {
    enabled: false,
    placeholder: "Type keyword or name",
    searchField: "searchTerm",
  },
  dropDownSearchOption1Title,
  dropDownSearchOption2Title,
  dropDownSearchOption1 = [],
  dropDownSearchOption2 = [],
  onRefetchTrigger,
  onRefetchComplete,
  setIsLoading,
  isTable,
  additionalFilters,
  columns,
  tableProps,
}: InfiniteListProps<T>) => {
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState<T[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [debouncedKeyword, setDebouncedKeyword] = useState<string>("");

  const [dropDownSearchOption1Data, setDropDownSearchOption1Data] = useState<
    Option[]
  >([]);
  const [dropDownSearchOption2Data, setDropDownSearchOption2Data] = useState<
    Option[]
  >([]);

  const filtersArray = [];

  // Only add risk types filter if the dropdown is provided
  if (dropDownSearchOption1 && dropDownSearchOption1.length > 0) {
    filtersArray.push({
      field: "filter[riskTypes]",
      operator: "eq",
      value: [
        ...(dropDownSearchOption1Data.map((risk) => risk.value) as [
          INVESTMENT_RISK_TYPE_ENUM
        ]),
        "",
      ],
    });
  }

  // Only add formats filter if the dropdown is provided
  if (dropDownSearchOption2 && dropDownSearchOption2.length > 0) {
    filtersArray.push({
      field: "filter[formats]",
      operator: "eq",
      value: [
        ...(dropDownSearchOption2Data.map((format) => format.value) as [
          INVESTMENT_FORMAT_ENUM
        ]),
        "",
      ],
    });
  }

  // Add any additional filters if provided
  if (additionalFilters) {
    filtersArray.push(...additionalFilters);
  }

  const {
    data: response,
    isSuccess,
    isFetching: isLoading,
    refetch,
  } = useCustom({
    url: endpoint,
    method: "get",
    config: {
      query: {
        limit,
        offset,
        searchTerm: debouncedKeyword,
        ...queryParams,
      },
      filters: filtersArray as any,
    },
  });

  // Debounce search to reduce unnecessary API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword !== debouncedKeyword) {
        clear(); // Reset items
        setDebouncedKeyword(keyword);
      }
    }, 500); // 500ms delay

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (response) {
      const paginatedData = response.data as PaginatedEntityList<T>;

      if (paginatedData.pagination.offset === 0) {
        setItems(paginatedData.data);
      } else {
        setItems((prevItems) => [...prevItems, ...paginatedData.data]);
      }
    }
  }, [response]);

  useEffect(() => {
    if (onRefetchTrigger) {
      clear();
      refetch().then(() => {
        onRefetchComplete?.();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRefetchTrigger]);

  useEffect(() => {
    setIsLoading?.(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const fetchMoreData = (inView: boolean) => {
    if (inView && response?.data?.pagination.nextOffset) {
      setOffset(response.data.pagination.nextOffset);
    }
  };

  const clear = () => {
    setItems([]);
    setOffset(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setKeyword(value);
  };

  const removeOption = (
    item: Option,
    setValue: React.Dispatch<React.SetStateAction<Option[]>>,
    array: Option[]
  ) => {
    const options = array.filter((selected) => selected.key !== item.key);
    setValue(options);
    clear();
  };

  const reset = () => {
    setKeyword("");
    setDebouncedKeyword("");
    clear();
  };

  return (
    <Box sx={{ marginY: 4 }}>
      {searchConfig.enabled && (
        <>
          <Grid container columnSpacing={1} rowSpacing={1}>
            {dropDownSearchOption1 && dropDownSearchOption1.length > 0 && (
              <Grid item xs={12} md={4}>
                <SelectMultipleCheckboxDropdown
                  setSelected={setDropDownSearchOption1Data}
                  options={dropDownSearchOption1}
                  selected={dropDownSearchOption1Data}
                  title={dropDownSearchOption1Title as string}
                  clear={clear}
                />
              </Grid>
            )}
            {dropDownSearchOption2 && dropDownSearchOption2.length > 0 && (
              <Grid item xs={12} md={4}>
                <FormControl size="small" fullWidth>
                  <SelectMultipleCheckboxDropdown
                    setSelected={setDropDownSearchOption2Data}
                    options={dropDownSearchOption2}
                    selected={dropDownSearchOption2Data}
                    title={dropDownSearchOption2Title as string}
                    clear={clear}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={
                !dropDownSearchOption1?.length && !dropDownSearchOption2?.length
                  ? 12
                  : !dropDownSearchOption2?.length ||
                    !dropDownSearchOption1?.length
                  ? 8
                  : 4
              }
            >
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder={searchConfig.placeholder}
                  size="small"
                  id="keyword"
                  name="keyword"
                  onChange={handleSearchChange}
                  value={keyword}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    keyword !== "" && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => reset()} edge="end">
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            sx={{ my: 2 }}
            container
            justifyContent={{ xs: "start", md: "flex-wrap" }}
            alignItems="center"
            columns={16}
            spacing={{ xs: 1, md: 0 }}
            rowSpacing={2}
          >
            {((dropDownSearchOption1Data &&
              dropDownSearchOption1Data.length > 0) ||
              (dropDownSearchOption2Data && dropDownSearchOption2Data.length) ||
              keyword) && (
              <Typography
                sx={{ color: "neutral.main" }}
                variant="subtitle3"
                component="span"
              >
                Showing results for
              </Typography>
            )}
            {dropDownSearchOption1Data.map((risk) => (
              <Grid key={risk.key} margin={{ md: 1 }}>
                <FormControl size="small">
                  <OutlinedInput
                    value={risk.label}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            removeOption(
                              risk,
                              setDropDownSearchOption1Data,
                              dropDownSearchOption1Data
                            )
                          }
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            ))}
            {dropDownSearchOption2Data.map((format) => (
              <Grid key={format.key} margin={{ md: 1 }}>
                <FormControl size="small">
                  <OutlinedInput
                    value={format.label}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            removeOption(
                              format,
                              setDropDownSearchOption2Data,
                              dropDownSearchOption2Data
                            )
                          }
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            ))}
            {keyword && (
              <Grid margin={{ md: 1 }}>
                <FormControl size="small">
                  <OutlinedInput
                    value={keyword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            reset();
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {isSuccess && (
        <>
          {isTable && columns ? (
            <TableContainer component={Paper}>
              <Table stickyHeader={tableProps?.stickyHeader}>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: tableProps?.headerBgColor || "grey.100",
                    }}
                  >
                    {columns.map((column, index) => (
                      <TableCell
                        key={`header-${index}`}
                        width={column.width}
                        sx={{ fontWeight: "bold" }}
                      >
                        {column.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .filter((item) =>
                      renderItem ? renderItem(item, 0) !== null : true
                    )
                    .map((item, rowIndex) => (
                      <TableRow key={`row-${rowIndex}`}>
                        {columns.map((column, colIndex) => (
                          <TableCell key={`cell-${rowIndex}-${colIndex}`}>
                            {column.renderCell
                              ? column.renderCell(item)
                              : typeof column.field === "function"
                              ? column.field(item)
                              : get(item, column.field as string)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid container spacing={gridProps.spacing}>
              {items

                .filter((item) => renderItem && renderItem(item, 0) !== null)
                .map((item, index) => (
                  <Grid
                    key={`item-${index}`}
                    item
                    xs={gridProps.xs}
                    sm={gridProps.sm}
                    md={gridProps.md}
                    lg={gridProps.lg}
                  >
                    {renderItem && renderItem(item, index)}
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      )}

      {isLoading ? (
        <Grid item xs={12} textAlign="center" sx={{ m: 3 }}>
          <CircularProgress color="primary" />
        </Grid>
      ) : response?.data?.pagination.hasNext ? (
        <InView as="div" onChange={fetchMoreData} />
      ) : null}

      {!isLoading && items.length === 0 && (
        <Typography variant="bodyCopyRegular" p={1}>
          {emptyMessage}
        </Typography>
      )}
    </Box>
  );
};
