import { IResourceComponentsProps, useUpdate } from "@refinedev/core";
import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { List } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  IInvestment,
  INVESTMENT_FORMAT_ENUM,
  INVESTMENT_RISK_TYPE_ENUM,
} from "interfaces/investment";
import { InvestmentCard } from "components/card/InvestmentCard";
import { InvestmentBatchUpdateModal } from "components/modal/investment-batch-update-modal";
import { InfiniteList } from "components/list/infinity-list";
export interface Option {
  key: number;
  label: string;
  value: string;
}
const risksOptions: Option[] = [
  {
    key: 1,
    label: "Equity",
    value: INVESTMENT_RISK_TYPE_ENUM.EQUITY,
  },
  {
    key: 2,
    label: "Fixed Income",
    value: INVESTMENT_RISK_TYPE_ENUM.FIXED_INCOME,
  },
  {
    key: 3,
    label: "Other",
    value: INVESTMENT_RISK_TYPE_ENUM.OTHER,
  },
];

const formatOptions: Option[] = [
  {
    key: 1,
    label: "Shares",
    value: INVESTMENT_FORMAT_ENUM.SHARES,
  },
  {
    key: 2,
    label: "ETF- Exchange Traded Funds",
    value: INVESTMENT_FORMAT_ENUM.ETF,
  },
  {
    key: 3,
    label: "Mutual Funds",
    value: INVESTMENT_FORMAT_ENUM.MUTUAL_FUNDS,
  },
  {
    key: 4,
    label: "Bond",
    value: INVESTMENT_FORMAT_ENUM.BOND,
  },
];

export const InvestmentsList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const { mutate, isLoading: isUpdateLoading } = useUpdate();
  const [hideZeroAmount, setHideZeroAmount] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setShouldRefetch(true);
  };

  const handleRefetchComplete = () => {
    setShouldRefetch(false);
  };

  const fetch = () => {
    mutate(
      {
        resource: "update-ticker-prices",
        values: {},
        id: "",
      },
      {
        onSuccess: () => {
          setShouldRefetch(true);
        },
      }
    );
  };

  return (
    <>
      <InvestmentBatchUpdateModal open={open} closeModal={closeModal} />
      <List
        title="Investment List"
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <Button
              color="primary"
              onClick={() => navigate("/investments/history")}
              variant="contained"
            >
              Edit
            </Button>
            <Button
              color="primary"
              onClick={fetch}
              variant="contained"
              disabled={isUpdateLoading}
            >
              Fetch new prices
            </Button>
          </>
        )}
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          },
        }}
      >
        <Box sx={{ marginY: 4 }}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideZeroAmount}
                  onChange={(e) => setHideZeroAmount(e.target.checked)}
                  color="primary"
                />
              }
              label="Hide investments with zero units"
            />
          </Grid>
          <InfiniteList<IInvestment>
            endpoint="investments"
            limit={5}
            renderItem={(investment) => {
              return !hideZeroAmount ||
                (investment.totalInvestments?.totalAmount !== 0 &&
                  investment.totalInvestments?.totalAmount !== null) ? (
                <InvestmentCard {...investment} />
              ) : null;
            }}
            searchConfig={{
              enabled: true,
              placeholder: "Search investments...",
              searchField: "searchTerm",
            }}
            dropDownSearchOption1Title="All Risk Types"
            dropDownSearchOption1={risksOptions}
            dropDownSearchOption2Title="All Formats"
            dropDownSearchOption2={formatOptions}
            onRefetchTrigger={shouldRefetch}
            onRefetchComplete={handleRefetchComplete}
          />
        </Box>
      </List>
    </>
  );
};
