import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List, ShowButton } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";
import { IRecommendedCharity } from "interfaces/recommended-charity";
import { InfiniteList } from "components/list/infinity-list";

export const RecommendedCharitiesPendingList: React.FC<
  IResourceComponentsProps
> = () => {
  const navigate = useNavigate();

  return (
    <List title="Pending Recommended charities">
      <InfiniteList<IRecommendedCharity>
        endpoint="recommended-charity"
        limit={10}
        isTable={true}
        columns={[
          {
            field: "recommendedCharityId",
            header: "ID",
          },
          {
            field: "user",
            header: "User",
            renderCell: function render({ user }) {
              return `${user.firstName} ${user.lastName}`;
            },
          },
          {
            field: "charityName",
            header: "Charity Name",
          },
          {
            field: "ein",
            header: "Ein",
          },
          {
            field: "contactName",
            header: "Contact Name",
          },
          {
            field: "contactEmail",
            header: "Contact Email",
          },
          {
            header: "Actions",
            renderCell: function render({ recommendedCharityId }) {
              return (
                <ShowButton
                  hideText
                  onClick={() =>
                    navigate(
                      `/recommended-charities/pending/${recommendedCharityId}`
                    )
                  }
                />
              );
            },
          },
        ]}
        additionalFilters={[
          {
            field: "filter[status]",
            operator: "eq",
            value: "PENDING",
          },
        ]}
        tableProps={{
          stickyHeader: true,
          headerBgColor: "grey.200",
          responsive: true,
        }}
        emptyMessage={`No request found!`}
      />
    </List>
  );
};
