import { DeleteButton, Edit } from "@refinedev/mui";
import {
  TextField,
  Box,
  CircularProgress,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  listItemClasses,
  Divider,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useShow } from "@refinedev/core";
import { warm, primary } from "style/color";

export const UpdateCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { queryResult } = useShow({
    resource: "categories",
    id,
  });

  const { data, isLoading: isLoadingCategory } = queryResult;
  const category = data?.data;

  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors, isLoading },
  } = useForm({
    refineCoreProps: {
      resource: "categories",
      id,
      errorNotification: (error) => {
        return {
          message: error?.message?.includes("duplicate key value")
            ? "Category already exist"
            : error?.message?.includes("violates foreign key constraint")
            ? "The following category is used in the platform and can not be deleted!"
            : "Oops! something went wrong, please try later.",
          type: "error",
        };
      },
    },
    warnWhenUnsavedChanges: true,
  });

  const isPageLoading = isLoading || formLoading || isLoadingCategory;

  return (
    <Edit
      isLoading={isPageLoading}
      saveButtonProps={saveButtonProps}
      footerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <DeleteButton
            recordItemId={id}
            variant="outlined"
            onSuccess={() => {
              navigate(`/categories`);
            }}
            errorNotification={(error: any) => {
              return {
                message: error?.message?.includes(
                  "violates foreign key constraint"
                )
                  ? "The following category is used in the platform and can not be deleted!"
                  : "Oops! something went wrong, please try later.",
                type: "error",
              };
            }}
          />
        </>
      )}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {isPageLoading ? (
          <CircularProgress />
        ) : (
          <>
            <form>
              <TextField
                {...register("categoryName", {
                  required: "This category name is required",
                })}
                error={!!(errors as any)?.categoryName}
                helperText={(errors as any)?.categoryName?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Category Name"
                name="categoryName"
                defaultValue={category?.categoryName}
              />
            </form>
            <Typography
              variant="body1"
              fontWeight="bold"
              color={"primary"}
              sx={{ my: 1 }}
            >
              Linked funds:
            </Typography>
            {category?.funds && category.funds.length > 0 ? (
              <List
                className="successor"
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",

                  [` & .${listItemClasses.root}:hover`]: {
                    backgroundColor: warm[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Grid alignItems="center" container>
                  {category.funds?.map(
                    (fund: {
                      fundAccountId: number;
                      fundAccountName: string;
                    }) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} textAlign={"start"}>
                          <ListItem
                            key={fund.fundAccountId}
                            onClick={() =>
                              navigate(
                                `/fund-accounts/show/${fund.fundAccountId}`
                              )
                            }
                          >
                            <ListItemText
                              sx={{
                                display: "inline-grid",
                                width: "auto",
                                flex: "none",
                              }}
                              secondary={
                                <Typography
                                  sx={{ color: primary[600] }}
                                  variant="smallCopyBold"
                                >
                                  {fund.fundAccountName}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <Divider key={fund.fundAccountId} />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </List>
            ) : (
              <Typography>No Fund found.</Typography>
            )}
          </>
        )}
      </Box>
    </Edit>
  );
};
