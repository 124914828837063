import { Box, Typography } from "@mui/material";
import { InfiniteList } from "components/list/infinity-list";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
interface GrantVestmentRequestsListProps {
  grantVestmentId: number;
}

export const GrantVestmentRequestsList: React.FC<
  GrantVestmentRequestsListProps
> = ({ grantVestmentId }) => {
  return (
    <Box>
      <InfiniteList<IGrantVestmentRequest>
        endpoint={`grant-vestments/${grantVestmentId}/requests`}
        isTable={true}
        limit={5}
        columns={[
          {
            field: "user",
            header: "User",
            renderCell: function render({ user }) {
              return `By ${user.firstName} ${user.lastName}`;
            },
          },
          {
            field: "fund",
            header: "Fund Name",
            renderCell: function render({ fund: fundAccount }) {
              return fundAccount.fundAccountName;
            },
          },
          {
            field: "status",
            header: "status",
          },

          {
            field: "type",
            header: "type",
          },
          {
            field: "transactionType",
            header: "Transaction Type",
          },
          {
            header: "Created At",
            renderCell: function render({ createdAt }) {
              return shortFormat(createdAt);
            },
          },
          {
            header: "Amount",
            renderCell: function render({ appliedAmount }) {
              return (
                <Typography
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  variant="bodyCopyBold"
                  gutterBottom
                >
                  ${moneyFormat(appliedAmount)}
                </Typography>
              );
            },
          },
        ]}
        tableProps={{
          stickyHeader: true,
          headerBgColor: "grey.200",
          responsive: true,
        }}
      />
    </Box>
  );
};
