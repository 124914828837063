import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import { InfiniteList } from "components/list/infinity-list";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios"; // Assuming you're using axios for API calls

interface ITag {
  tagId: string;
  tagName: string;
}

interface TagsSelectProps {
  value: string[];
  onChange: (tagIds: string[]) => void;
  error?: boolean;
  helperText?: string;
  initialTags?: ITag[]; // New prop for update form
}

export const TagsInfiniteSelect: React.FC<TagsSelectProps> = ({
  value = [],
  onChange,
  error,
  helperText,
  initialTags,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedTagsData, setSelectedTagsData] = useState<ITag[]>([]);
  const [loading, setLoading] = useState(false);

  // Effect to initialize from props
  useEffect(() => {
    // Initialize from initialTags if provided
    if (initialTags && initialTags.length > 0) {
      setSelectedTagsData(initialTags);
      setSelectedTags(initialTags.map((tag) => tag.tagId));
    }
    // Initialize from value if provided
    else if (value && value.length > 0 && selectedTagsData.length === 0) {
      fetchTagsById(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Separate useEffect to handle initialTags changes during component lifecycle
  useEffect(() => {
    if (
      initialTags &&
      JSON.stringify(initialTags.map((t) => t.tagId).sort()) !==
        JSON.stringify(selectedTags.sort())
    ) {
      setSelectedTagsData(initialTags);
      setSelectedTags(initialTags.map((tag) => tag.tagId));
      onChange(initialTags.map((tag) => tag.tagId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTags]);

  const fetchTagsById = async (tagIds: string[]) => {
    if (!tagIds.length) return;

    try {
      setLoading(true);
      // You may need to adjust this endpoint to match your API structure
      const response = await axios.get("/api/tags", {
        params: { ids: tagIds.join(",") },
      });

      if (response.data) {
        setSelectedTagsData(response.data);
        setSelectedTags(tagIds);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTagSelect = (tag: ITag) => {
    let newSelectedTags;
    let newSelectedTagsData;

    if (selectedTags.includes(tag.tagId)) {
      // Remove tag if already selected
      newSelectedTags = selectedTags.filter((id) => id !== tag.tagId);
      newSelectedTagsData = selectedTagsData.filter(
        (t) => t.tagId !== tag.tagId
      );
    } else {
      // Add tag if not selected
      newSelectedTags = [...selectedTags, tag.tagId];
      newSelectedTagsData = [...selectedTagsData, tag];
    }

    setSelectedTags(newSelectedTags);
    setSelectedTagsData(newSelectedTagsData);
    onChange(newSelectedTags);
  };

  const handleRemoveTag = (tagId: string, event?: React.MouseEvent) => {
    // Prevent the TextField from opening the dialog when removing a tag
    if (event) {
      event.stopPropagation();
    }

    const newSelectedTags = selectedTags.filter((id) => id !== tagId);
    const newSelectedTagsData = selectedTagsData.filter(
      (tag) => tag.tagId !== tagId
    );

    setSelectedTags(newSelectedTags);
    setSelectedTagsData(newSelectedTagsData);
    onChange(newSelectedTags);
  };

  return (
    <>
      <TextField
        value=""
        onClick={handleOpen}
        error={!!error}
        helperText={helperText}
        margin="normal"
        fullWidth
        InputLabelProps={{ hidden: true }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ ml: 1 }} />
              ) : (
                selectedTagsData.map((tag) => (
                  <Chip
                    key={tag.tagId}
                    label={tag.tagName}
                    color="primary"
                    onDelete={(event) => handleRemoveTag(tag.tagId)}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => {
                          event.stopPropagation();
                          handleRemoveTag(tag.tagId, event);
                        }}
                      />
                    }
                    sx={{ m: 0.5 }}
                    onClick={(event) => event.stopPropagation()}
                  />
                ))
              )}
            </Box>
          ),
        }}
        name="tagIds"
        placeholder={selectedTagsData.length > 0 ? "" : "Select Tags"}
        style={{ marginTop: "1px" }}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Select Tags</DialogTitle>
        <DialogContent>
          <InfiniteList
            endpoint="tags"
            limit={10}
            renderItem={(tag: ITag) => (
              <Button
                key={tag.tagId}
                fullWidth
                variant={
                  selectedTags.includes(tag.tagId) ? "contained" : "outlined"
                }
                onClick={() => {
                  handleTagSelect(tag);
                }}
              >
                {tag.tagName}
              </Button>
            )}
            searchConfig={{
              enabled: true,
              placeholder: "Search tags",
              searchField: "searchTerm",
            }}
            emptyMessage="No tags found"
          />

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} variant="contained">
              Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
