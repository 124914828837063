import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from "@mui/material";
import { InfiniteList } from "components/list/infinity-list";
import axios from "axios"; // Assuming you're using axios for API calls

interface ICategory {
  categoryId: string;
  categoryName: string;
}

interface CategorySelectProps {
  value: string;
  onChange: (categoryId: string) => void;
  error?: boolean;
  helperText?: string;
  initialCategory?: ICategory; // Optional prop for update form
}

export const CategoryInfiniteSelect: React.FC<CategorySelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  initialCategory,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  // Effect for handling initialCategory or value changes
  useEffect(() => {
    // If we have an initialCategory, use that for initial value
    if (initialCategory && !selectedCategory) {
      setSelectedCategory(initialCategory);
      return;
    }

    // If we have a value (categoryId) but no selectedCategory, fetch category details
    if (value && !selectedCategory) {
      fetchCategoryById(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initialCategory]);

  const fetchCategoryById = async (categoryId: string) => {
    if (!categoryId) return;

    try {
      setLoading(true);
      // Adjust this endpoint to match your API structure
      const response = await axios.get(`/api/categories/${categoryId}`);
      if (response.data) {
        setSelectedCategory(response.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCategorySelect = (category: ICategory) => {
    setSelectedCategory(category);
    onChange(category.categoryId);
    handleClose();
  };

  return (
    <>
      <TextField
        value={selectedCategory ? selectedCategory.categoryName : ""}
        onClick={handleOpen}
        error={!!error}
        helperText={helperText}
        margin="normal"
        fullWidth
        InputLabelProps={{ hidden: true }}
        InputProps={{
          readOnly: true,
          endAdornment: loading ? <CircularProgress size={20} /> : null,
        }}
        name="categoryId"
        placeholder="Select Category"
        style={{ marginTop: "1px" }}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Select Category</DialogTitle>
        <DialogContent>
          <InfiniteList
            endpoint="categories"
            limit={10}
            renderItem={(category: ICategory) => (
              <Button
                key={category.categoryId}
                fullWidth
                variant={
                  category.categoryId === selectedCategory?.categoryId
                    ? "contained"
                    : "outlined"
                }
                onClick={() => {
                  handleCategorySelect(category);
                }}
              >
                {category.categoryName}
              </Button>
            )}
            searchConfig={{
              enabled: true,
              placeholder: "Search categories",
              searchField: "searchTerm",
            }}
            emptyMessage="No categories found"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
