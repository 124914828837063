import React from "react";
import { List, DateField } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { moneyFormat } from "utils/money-format";
import { InfiniteList } from "components/list/infinity-list";

export const RejectedGrantVestmentRequestList: React.FC<
  IResourceComponentsProps
> = () => {
  return (
    <>
      <List title="Rejected GrantVestment Request List">
        <InfiniteList<IGrantVestmentRequest>
          endpoint="grant-vestment-requests"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "grantVestmentRequestId",
              header: "ID",
            },
            {
              field: "grantVestment",
              header: "Grant vestment title",
              width: 200,
              renderCell: function render({ grantVestment }) {
                return grantVestment.name;
              },
            },
            {
              field: "fund",
              header: "Fund Name",
              width: 200,
              renderCell: function render({ fund }) {
                return fund.fundAccountName;
              },
            },
            {
              field: "user",
              header: "Advisor",
              width: 200,
              renderCell: function render({ user }) {
                return `${user.firstName} ${user.lastName}`;
              },
            },
            {
              field: "requestedAmount",

              header: "Requested Amount",
              renderCell: function render({ requestedAmount }) {
                return <>${moneyFormat(requestedAmount)}</>;
              },
            },
            {
              field: "status",
              header: "Status",
            },
            {
              field: "createdAt",
              header: "Created Date",
              renderCell: function render({ createdAt }) {
                return <DateField value={createdAt} />;
              },
            },
            {
              field: "updatedAt",
              header: "Rejection Date",
              renderCell: function render({ updatedAt }) {
                return <DateField value={updatedAt} />;
              },
            },
            {
              field: "rejectionReason",
              header: "Rejection reason",
              width: 200,
            },
          ]}
          additionalFilters={[
            {
              field: "filter[status]",
              operator: "eq",
              value: "REJECTED",
            },
            {
              field: "filter[transfer]",
              operator: "eq",
              value: "false",
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          emptyMessage={`No GrantVestment request found!`}
        />
      </List>
    </>
  );
};
