import React, { useState } from "react";
import { List, DateField } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { moneyFormat } from "utils/money-format";
import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { EvaluateGrantVestmentRequestModal } from "components/modal/evaluate-grant-vestment-request";
import { InfiniteList } from "components/list/infinity-list";

export const ApprovedGrantVestmentRequestList: React.FC<
  IResourceComponentsProps
> = () => {
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    grantvestmentRequest: IGrantVestmentRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED" | "PROCESSED";
  }>({ grantvestmentRequest: undefined, value: "PENDING" });

  return (
    <>
      <List title="Approved GrantVestment Request List">
        <InfiniteList<IGrantVestmentRequest>
          endpoint="grant-vestment-requests"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "grantVestmentRequestId",
              header: "ID",
            },
            {
              field: "grantVestment",
              header: "Grant vestment title",
              width: 200,
              renderCell: function render({ grantVestment }) {
                return grantVestment.name;
              },
            },
            {
              field: "fund",
              header: "Fund Name",
              width: 200,
              renderCell: function render({ fund }) {
                return fund.fundAccountName;
              },
            },
            {
              field: "user",
              header: "Advisor",
              renderCell: function render({ user }) {
                return `${user.firstName} ${user.lastName}`;
              },
            },
            {
              field: "appliedAmount",
              header: "Applied Amount",
              renderCell: function render({ appliedAmount }) {
                return <>${moneyFormat(appliedAmount)}</>;
              },
            },
            {
              field: "status",
              header: "Status",
            },
            {
              field: "createdAt",
              header: "Created Date",
              renderCell: function render({ createdAt }) {
                return <DateField value={createdAt} />;
              },
            },
            {
              field: "updatedAt",
              header: "Accepting Date",
              renderCell: function render({ updatedAt }) {
                return <DateField value={updatedAt} />;
              },
            },
            {
              header: "Process",
              renderCell: function render(gvReq) {
                return (
                  <>
                    {" "}
                    <Button
                      variant="contained"
                      onClick={() => {
                        setDecision({
                          grantvestmentRequest: gvReq,
                          value: "PROCESSED",
                        });
                        setOpen(true);
                      }}
                    >
                      <CheckIcon />
                    </Button>
                  </>
                );
              },
            },
          ]}
          additionalFilters={[
            {
              field: "filter[status]",
              operator: "eq",
              value: "APPROVED",
            },
            {
              field: "filter[transfer]",
              operator: "eq",
              value: "false",
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          emptyMessage={`No GrantVestment request found!`}
        />
      </List>
      <EvaluateGrantVestmentRequestModal
        visible={open}
        close={setOpen}
        data={decision}
      />
    </>
  );
};
