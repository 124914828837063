import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { DateField, List, ShowButton } from "@refinedev/mui";
import { IContributionTransaction } from "interfaces/contribution-transaction";
import { useNavigate } from "react-router-dom";
import { InfiniteList } from "components/list/infinity-list";
export const CanceledContributionTransactionList: React.FC<
  IResourceComponentsProps
> = () => {
  const navigate = useNavigate();
  return (
    <List title="Contribution transactions">
      <InfiniteList<IContributionTransaction>
        endpoint="contribution-transactions"
        limit={10}
        isTable={true}
        columns={[
          {
            field: "contributionId",
            header: "ID",
          },
          {
            field: "fund",
            header: "Fund Name",

            renderCell: function render({ fund }) {
              return fund.fundAccountName;
            },
          },
          {
            field: "contributionReport",
            header: "Donor",

            renderCell: function render({ contributionReport }) {
              return contributionReport.name;
            },
          },
          {
            field: "type",
            header: "Contribution type",
          },
          {
            field: "status",

            header: "Contribution status",
          },

          {
            field: "createdAt",
            header: "Created Date",
            renderCell: function render({ createdAt }) {
              return <DateField value={createdAt} />;
            },
          },
          {
            header: "Actions",
            renderCell: function render({ contributionId }) {
              return (
                <ShowButton
                  hideText
                  onClick={() =>
                    navigate(
                      `/contribution-transactions/canceled/${contributionId}`
                    )
                  }
                />
              );
            },
          },
        ]}
        additionalFilters={[
          {
            field: "status",
            operator: "eq",
            value: "CANCELED",
          },
        ]}
        tableProps={{
          stickyHeader: true,
          headerBgColor: "grey.200",
          responsive: true,
        }}
        emptyMessage={`No contribution request found!`}
      />
    </List>
  );
};
