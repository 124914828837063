import React from "react";
import { List, DateField } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { moneyFormat } from "utils/money-format";
import {
  ITransferTransaction,
  TRANSFER_TRANSACTION_TYPE_ENUM,
} from "interfaces/transfer-transaction";
import { InfiniteList } from "components/list/infinity-list";

export const ProcessedTransferTransactionList: React.FC<
  IResourceComponentsProps
> = () => {
  return (
    <>
      <List title="Processed Transfer Transaction List">
        <InfiniteList<ITransferTransaction>
          endpoint="transfer-transactions"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "transferId",
              header: "ID",
            },
            {
              field: "senderFund",
              header: "From",
              renderCell: function render({ senderFund }) {
                return senderFund.fundAccountName;
              },
            },
            {
              field: "receiverFund",
              header: "To",
              renderCell: function render({ receiverFund }) {
                return receiverFund.fundAccountName;
              },
            },
            {
              field: "user",
              header: "Advisor",
              renderCell: function render({ user }) {
                return `${user.firstName} ${user.lastName}`;
              },
            },
            {
              field: "transferType",
              header: "Type",
            },
            {
              field: "reference",
              header: "Reference",
            },

            {
              field: "amount",
              header: "Value",
              renderCell: function render(transfer) {
                if (
                  transfer.transferType ===
                  TRANSFER_TRANSACTION_TYPE_ENUM.INVESTMENTS
                ) {
                  return <>{transfer.amount} unit(s)</>;
                } else {
                  return <>${moneyFormat(transfer.amount)}</>;
                }
              },
            },
            {
              field: "status",
              header: "Status",
            },
            {
              field: "executionDate",
              header: "Date",
              renderCell: function render({ executionDate }) {
                return <DateField value={executionDate} />;
              },
            },
          ]}
          additionalFilters={[
            {
              field: "status",
              operator: "eq",
              value: "PROCESSED",
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          emptyMessage={`No transaction found!`}
        />
      </List>
    </>
  );
};
