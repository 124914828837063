import React from "react";
import { List, ShowButton } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { IGrantVestment } from "interfaces/grant-vestment";
import { InfiniteList } from "components/list/infinity-list";
import { useNavigate } from "react-router-dom";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";

export const GrantVestmentList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();

  return (
    <>
      <List
        title="GrantVestment List"
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 2,
          },
        }}
      >
        <InfiniteList<IGrantVestment>
          endpoint="grant-vestments"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "name",
              header: "Name",
            },
            {
              field: "projectBy",
              header: "Projected By",
            },
            {
              header: "Goal",
              renderCell: function render({ goalAmount }) {
                return `${moneyFormat(goalAmount)}`;
              },
            },
            {
              header: "GrantVestors",
              renderCell: function render({ grantVestorCounter }) {
                return `${grantVestorCounter} Investors`;
              },
            },
            {
              field: "status",
              header: "Status",
            },
            {
              header: "Deadline",
              renderCell: function render({ deadline }) {
                return shortFormat(deadline);
              },
            },
            {
              field: "status",
              header: "status",
            },

            {
              header: "Actions",
              renderCell: function render({ grantVestmentId }) {
                return (
                  <>
                    <ShowButton
                      hideText
                      onClick={() =>
                        navigate(`/grant-vestments/${grantVestmentId}`)
                      }
                    />
                  </>
                );
              },
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          searchConfig={{
            enabled: true,
            placeholder: "Search GrantVestment...",
            searchField: "searchTerm",
          }}
          emptyMessage={`No GrantVestment found!`}
        />
      </List>
    </>
  );
};
