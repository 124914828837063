import React, { useState } from "react";
import { Show } from "@refinedev/mui";
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useShow } from "@refinedev/core";
import { primary } from "style/color";
import { useNavigate, useParams } from "react-router-dom";
import { IInvestmentRequest } from "interfaces/investment-requests";
import { moneyFormat } from "utils/money-format";
import { EvaluateInvestmentRequestModal } from "components/modal/evaluate-investment-request";
import { longDateFormat } from "utils/date-format";
export const InvestmentRequestDetails: React.FC = () => {
  const { id } = useParams();
  const { queryResult } = useShow<IInvestmentRequest>({
    resource: "investments-request",
    id: id,
  });
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    investmentRequest: IInvestmentRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED";
  }>({ investmentRequest: undefined, value: "PENDING" });
  const navigate = useNavigate();
  const { data } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={queryResult.isLoading}
      title={
        <Typography variant="h5" color={primary}>
          Investment Request Details
        </Typography>
      }
    >
      {!queryResult.isLoading && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                value={`${record?.user?.title ? record?.user?.title : ""} ${
                  record?.user?.firstName ? record?.user?.firstName : ""
                } ${record?.user?.middleName ? record?.user?.middleName : ""} ${
                  record?.user?.lastName ? record.user?.lastName : ""
                } ${record?.user?.suffix ? record?.user?.suffix : ""}`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="User "
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.fund?.fundAccountName}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Fund Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.investment?.ticker}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Ticker"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.type}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Type "
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={`$${moneyFormat(record?.requestedAmount || 0)}`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Requested Amount "
              />
            </Grid>
            {record?.status === "APPROVED" && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={`$${moneyFormat(record?.appliedAmount || 0)}`}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Applied Amount "
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={`${moneyFormat(record?.requestedUnits || 0, 4)} unit(s)`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Request Units "
              />
            </Grid>
            {record?.status !== "PENDING" && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={`${moneyFormat(record?.appliedUnits || 0, 4)} unit(s)`}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Applied Units "
                />{" "}
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={longDateFormat(record?.createdAt as Date)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="CreatedAt"
              />
            </Grid>
            {record?.status !== "PENDING" && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  value={longDateFormat(record?.updatedAt as Date)}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label={
                    record?.status === "APPROVED" ? "AcceptedAt" : "RejectedAt"
                  }
                />
              </Grid>
            )}
            {record?.status === "REJECTED" && record.rejectionReason && (
              <Grid item xs={12} md={12}>
                <TextField
                  disabled
                  value={record.rejectionReason}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="textarea"
                  label="Rejection reason "
                  multiline={true}
                  rows={3}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography color={primary}>
                User is interested in <Chip label={record?.interestedIn} />
              </Typography>
            </Grid>
            {record?.grantRequestId && (
              <Grid item xs={12} md={12}>
                <Typography color={primary}>
                  This investment request belong to the following{" "}
                  <Link
                    onClick={() =>
                      navigate(`/grant-requests/${record?.grantRequestId}`, {
                        replace: true,
                      })
                    }
                    underline="always"
                    color={"inherit"}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    grant request
                  </Link>
                </Typography>
              </Grid>
            )}
            {record?.grantVestmentRequestId && (
              <Grid item xs={12} md={12}>
                <Typography color={primary}>
                  This investment request belong to the following{" "}
                  <Link
                    onClick={() =>
                      navigate(`/grant-vestments-requests/status/pending`, {
                        replace: true,
                      })
                    }
                    underline="always"
                    color={"inherit"}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    GrantVestment request with ID{" "}
                    <Chip label={record?.grantVestmentRequestId} />
                  </Link>
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} md={12} textAlign={"end"}>
              {record?.status === "PENDING" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",

                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <EvaluateInvestmentRequestModal
                    visible={open}
                    close={setOpen}
                    data={decision}
                  />

                  <Button
                    size="large"
                    variant="contained"
                    style={{ marginRight: "0.5rem" }}
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                      setDecision({
                        investmentRequest: record,
                        value: "APPROVED",
                      });
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                      setDecision({
                        investmentRequest: record,
                        value: "REJECTED",
                      });
                    }}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>{" "}
        </>
      )}
    </Show>
  );
};
