import React, { useState } from "react";
import { EditButton, List, ShowButton } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FeedModal } from "components/modal/adjustment-fee-modal";
import { InfiniteList } from "components/list/infinity-list";
import { IFundAccount } from "interfaces/fund-account";
import { moneyFormat } from "utils/money-format";

export const FundAccountList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fund, setFund] = useState({ fundName: "", fundId: 0 });
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const handleRefetchComplete = () => {
    setShouldRefetch(false);
  };

  return (
    <>
      <List
        title="Fund Account List"
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          },
        }}
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <Button
              color="primary"
              onClick={() => navigate("/fund-accounts/add-fee")}
              variant="contained"
            >
              Add Fee
            </Button>
          </>
        )}
      >
        <Box>
          <InfiniteList<IFundAccount>
            endpoint="funds"
            limit={5}
            queryParams={{ "orderBy[reference]": "ASC" }}
            isTable={true}
            tableProps={{
              stickyHeader: true,
              headerBgColor: "grey.200",
              responsive: true,
            }}
            columns={[
              {
                field: "reference",
                header: "Reference",
              },
              {
                field: "fundAccountName",
                header: "Name",
              },
              {
                header: "Type",
                renderCell: function render({ entityInformation }) {
                  return entityInformation ? "Entity" : "Personal";
                },
              },
              {
                header: "Available",
                renderCell: function render({ availableBalance }) {
                  return `$${moneyFormat(availableBalance, 2)}`;
                },
              },
              {
                header: "Total",
                renderCell: function render({ total }) {
                  return `$${moneyFormat(total, 2)}`;
                },
              },

              {
                header: "View",
                renderCell: function render({ fundAccountId }) {
                  return (
                    <>
                      <ShowButton
                        hideText
                        onClick={() =>
                          navigate(`/fund-accounts/show/${fundAccountId}`)
                        }
                      />
                    </>
                  );
                },
              },
              {
                header: "Edit",
                renderCell: function render({
                  fundAccountId,
                  fundAccountName,
                }) {
                  return (
                    <>
                      <EditButton
                        hideText
                        onClick={() => {
                          setFund({
                            fundId: fundAccountId,
                            fundName: fundAccountName as string,
                          });
                          setOpen(true);
                        }}
                      />
                    </>
                  );
                },
              },
            ]}
            searchConfig={{
              enabled: true,
              placeholder: "Search Fund by name...",
              searchField: "searchTerm",
            }}
            onRefetchTrigger={shouldRefetch}
            onRefetchComplete={handleRefetchComplete}
            emptyMessage={`No Fund found!`}
          />
        </Box>
      </List>
      <FeedModal
        setOpen={setOpen}
        open={open}
        fund={fund}
        refetch={() => setShouldRefetch(true)}
      />
    </>
  );
};
