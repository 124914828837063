import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { neutral, primary } from "style/color";
import { moneyFormat, unitFormat } from "utils/money-format";

interface ModalProps {
  visible: boolean;
  data: {
    grantvestmentRequest: IGrantVestmentRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED" | "PROCESSED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateGrantVestmentRequestModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [appliedAmount, setAppliedAmount] = useState(0);
  const [error, setError] = useState(false);
  const date = new Date().toISOString().substring(0, 10);
  const [executionDate, setExecutionDate] = useState({
    value: date,
    error: false,
  });
  const [rejectionReason, setRejectionReason] = useState("");
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    if (data.grantvestmentRequest?.status === "PENDING") {
      setAppliedAmount(data.grantvestmentRequest.requestedAmount);
    }

    if (data.grantvestmentRequest?.status === "APPROVED") {
      setAppliedAmount(data.grantvestmentRequest.appliedAmount);
    }
  }, [data.grantvestmentRequest]);
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        {data.value === "APPROVED"
          ? "Approve"
          : data.value === "PROCESSED"
          ? "Process"
          : "Reject"}{" "}
        GrantVestment Request
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to{" "}
          {data.value === "APPROVED"
            ? "Approve"
            : data.value === "PROCESSED"
            ? "process"
            : "reject"}{" "}
          this GrantVestment request?
        </Typography>
        {data.value === "APPROVED" && (
          <div>
            {data.grantvestmentRequest?.transactionType === "CASH" ? (
              <>
                {" "}
                <TextField
                  value={moneyFormat(
                    data?.grantvestmentRequest!.requestedAmount
                  )}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Requested Amount"
                  name="requestedAmount"
                  disabled
                />
                <TextField
                  value={appliedAmount}
                  error={error}
                  helperText={error && "The applied amount is required"}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Applied Amount"
                  name="appliedAmount"
                  onChange={(e) => {
                    setAppliedAmount(e.target.value as any);
                    setError(false);
                  }}
                />
              </>
            ) : (
              <>
                <Typography variant="body2" color={neutral[700]} mb={2}>
                  The following Grantvestment request is linked to an investment
                  sell request and needs to be approved to proceed with the sell
                  request.
                </Typography>

                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2} pl={2}>
                  <Grid item xs={4}>
                    <Typography variant="caption" color={neutral[500]}>
                      Ticker
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={primary[700]}
                    >
                      {data.grantvestmentRequest?.investmentInfo?.ticker || "—"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="caption" color={neutral[500]}>
                      Units
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={primary[700]}
                    >
                      {unitFormat(
                        data.grantvestmentRequest?.investmentInfo
                          ?.units as number
                      ) || "—"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="caption" color={neutral[500]}>
                      Amount
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={primary[700]}
                    >
                      $
                      {moneyFormat(
                        data.grantvestmentRequest?.investmentInfo
                          ?.requestedAmount as number
                      ) || "—"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <TextField
                  value={appliedAmount}
                  error={error}
                  helperText={error && "The applied amount is required"}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Applied Amount"
                  name="appliedAmount"
                  onChange={(e) => {
                    setAppliedAmount(e.target.value as any);
                    setError(false);
                  }}
                />
              </>
            )}
          </div>
        )}{" "}
        {data.value === "REJECTED" && (
          <>
            <TextField
              value={rejectionReason}
              error={error}
              helperText={error && "The rejection reason is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Rejection reason"
              name="rejectionReason"
              multiline={true}
              rows={3}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </>
        )}
        {data.value === "PROCESSED" && (
          <div>
            {data.grantvestmentRequest?.transactionType === "CASH" ? (
              <>
                <Typography variant="caption" paddingLeft={1}>
                  Initially requested amount:
                  {data.grantvestmentRequest?.requestedAmount}
                </Typography>
                <br></br>
                <Typography variant="caption" paddingLeft={1}>
                  Applied amount at the acceptation step:
                  {data.grantvestmentRequest?.appliedAmount}
                </Typography>
                <TextField
                  value={appliedAmount}
                  error={error}
                  helperText={error && "The applied amount is required"}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Confirm Applied Amount"
                  name="appliedAmount"
                  onChange={(e) => {
                    setAppliedAmount(e.target.value as any);
                    setError(false);
                  }}
                />
                <TextField
                  id="executionDate"
                  name="executionDate"
                  error={executionDate.error}
                  helperText={
                    executionDate.error &&
                    "The date is required, and can not be negative value!"
                  }
                  defaultValue={executionDate.value}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="Execution Date"
                  onChange={(event) => {
                    setExecutionDate({
                      value: event.target.value,
                      error: false,
                    });
                  }}
                />
              </>
            ) : (
              <>
                <Typography variant="body2" color={neutral[700]} mb={2}>
                  The following Grantvestment requested is linked to an
                  investment sell request.
                </Typography>

                {data.grantvestmentRequest?.investmentInfo?.appliedAmount !==
                  data.grantvestmentRequest?.requestedAmount && (
                  <Typography variant="body2" color={neutral[700]} mb={2}>
                    You are selling investments for $
                    {data.grantvestmentRequest?.investmentInfo?.appliedAmount},
                    which is different than the recommended $
                    {data.grantvestmentRequest?.requestedAmount}, for the GV, so
                    please remember to adjust the GV amount when processing if
                    necessary
                  </Typography>
                )}
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={3} ml={2}>
                    <Typography variant="caption" color={neutral[500]}>
                      Ticker
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={primary[700]}
                    >
                      {data.grantvestmentRequest?.investmentInfo?.ticker || "—"}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} ml={2}>
                    <Typography variant="caption" color={neutral[500]}>
                      Recommended Amount
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={primary[700]}
                    >
                      $
                      {moneyFormat(
                        data.grantvestmentRequest?.requestedAmount as number
                      ) || "—"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} ml={5}>
                    <Typography variant="caption" color={neutral[500]}>
                      Selling price
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={primary[700]}
                    >
                      $
                      {moneyFormat(
                        data.grantvestmentRequest?.investmentInfo
                          ?.appliedAmount as number
                      ) || "—"}
                    </Typography>
                  </Grid>
                </Grid>
                <TextField
                  value={appliedAmount}
                  error={error}
                  helperText={error && "The applied amount is required"}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  label="Confirm Applied Amount"
                  name="appliedAmount"
                  onChange={(e) => {
                    setAppliedAmount(e.target.value as any);
                    setError(false);
                  }}
                />
                <TextField
                  id="executionDate"
                  name="executionDate"
                  error={executionDate.error}
                  helperText={
                    executionDate.error &&
                    "The date is required, and can not be negative value!"
                  }
                  defaultValue={executionDate.value}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="Execution Date"
                  onChange={(event) => {
                    setExecutionDate({
                      value: event.target.value,
                      error: false,
                    });
                  }}
                />
              </>
            )}

            <Typography variant="caption">
              *You can not undo this action once you process the request.{" "}
            </Typography>
          </div>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (
                (!appliedAmount && data.value === "APPROVED") ||
                (!appliedAmount && data.value === "PROCESSED")
              ) {
                setError(true);
              } else {
                mutate(
                  {
                    resource: "grant-vestment-requests",
                    values:
                      data.value === "APPROVED"
                        ? {
                            status: data.value,
                            appliedAmount: parseFloat(
                              appliedAmount.toString().replace(",", "")
                            ),
                          }
                        : data.value === "REJECTED"
                        ? {
                            rejectionReason: rejectionReason,
                            status: data.value,
                          }
                        : {
                            executionDate: executionDate.value,
                            status: data.value,
                            appliedAmount: parseFloat(
                              appliedAmount.toString().replace(",", "")
                            ),
                          },
                    id: `${
                      data.grantvestmentRequest?.grantVestmentRequestId as any
                    }/status`,

                    errorNotification: (error) => {
                      return {
                        message: (error?.message as string).includes(
                          "The Investment related to the Grantvestment is"
                        )
                          ? "The Investment related to the Grantvestment is Pending"
                          : (error?.message as string),
                        description: "Error",
                        type: "error",
                      };
                    },
                  },
                  {
                    onSuccess: () => {
                      navigate("/contribution-transactions");
                      setError(false);
                      close(false);
                      data.value === "APPROVED"
                        ? navigate("/grant-vestments-requests/status/approved")
                        : data.value === "PROCESSED"
                        ? navigate("/grant-vestments-requests/status/processed")
                        : navigate("/grant-vestments-requests/status/rejected");
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
