import React, { useEffect, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List, ShowButton } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";
import {
  GRANT_REQUEST_STATUS_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  IGrantRequest,
  OnTimeSchedule,
  RecurrentSchedule,
} from "interfaces/grant-requests";
import { shortFormat } from "utils/date-format";
import { Box } from "@mui/material";
import { InfiniteList } from "components/list/infinity-list";

export const GrantRequestsList: React.FC<
  IResourceComponentsProps & {
    status: GRANT_REQUEST_STATUS_ENUM;
  }
> = ({ status }) => {
  const navigate = useNavigate();
  const [statusKey, setStatusKey] = useState(status);

  useEffect(() => {
    // Update the key when status changes
    if (status !== statusKey) {
      setStatusKey(status);
    }
  }, [status, statusKey]);

  return (
    <List
      headerProps={{
        sx: {
          textTransform: "capitalize",
        },
      }}
      title={`${status.toLowerCase()} Grant Requests`}
    >
      <Box>
        <InfiniteList<IGrantRequest>
          key={statusKey}
          endpoint="grant-requests"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "grantRequestId",
              header: "ID",
            },
            {
              field: "charity",
              header: "Charity",
              renderCell: function render({ charity }) {
                return charity.name;
              },
            },
            {
              field: "fundAccount",
              header: "Fund Name",
              renderCell: function render({ fundAccount }) {
                return fundAccount.fundAccountName;
              },
            },
            {
              field: "user",
              header: "Donar",
              renderCell: function render({ user }) {
                return `${user.firstName} ${user.lastName}`;
              },
            },
            {
              field: "type",
              header: "type",
            },
            {
              field: "status",
              header: "status",
            },
            {
              field: "transactionType",
              header: "Transaction Type",
            },
            {
              header: "Execution Date",
              renderCell: function render({ scheduleInformation, type }) {
                if (type === GRANT_REQUEST_TYPE_ENUM.ONETIME) {
                  return shortFormat(
                    (scheduleInformation as OnTimeSchedule).executionDate
                  );
                } else {
                  const recurrentScheduleInformation =
                    scheduleInformation as RecurrentSchedule;
                  return shortFormat(
                    recurrentScheduleInformation.nextExecutionDate
                      ? recurrentScheduleInformation.nextExecutionDate
                      : recurrentScheduleInformation.lastExecutionDate
                  );
                }
              },
            },
            {
              header: "Actions",
              renderCell: function render({ grantRequestId }) {
                return (
                  <>
                    <ShowButton
                      hideText
                      onClick={() =>
                        navigate(`/grant-requests/${grantRequestId}`)
                      }
                    />
                  </>
                );
              },
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          additionalFilters={[
            {
              field: "filter[status]",
              operator: "eq",
              value: status,
            },
          ]}
          emptyMessage={`No ${status.toLocaleLowerCase()} grant request found!`}
        />
      </Box>
    </List>
  );
};
