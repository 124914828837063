import { CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { IRecommendedCharity } from "interfaces/recommended-charity";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  visible: boolean;
  data: {
    recommendedCharity: IRecommendedCharity | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateRecommendedCharityModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [charity, setCharity] = useState({
    ein: '',
    name: '',
    mission: '',
    organizationUrl: '',
    cause: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  })
  const [error, setError] = useState(false);
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();
  useEffect(() => {
    if (data.recommendedCharity) {
      setCharity({
        ...charity,
        ein: data.recommendedCharity.ein,
        name: data.recommendedCharity.charityName
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.recommendedCharity]);
  const handleChange = (key: string, value: string) => {

    setCharity({
      ...charity,
      [key]: value
    })
  };
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        {data.value === "APPROVED" ? "Approve" : "Reject"} Recommended charity
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>

          {data.value === "APPROVED" ? "Please fill the missing information for this charity" : ""}
        </Typography>
        {data.value === "APPROVED" && (
          <>
            <TextField
              required
              value={charity.ein}
              error={error}
              helperText={error && "The ein is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="EIN"
              name="ein"
              onChange={(e) => handleChange("ein", e.target.value)}
            />
            <TextField
              required
              value={charity.name}
              error={error}
              helperText={error && "The charity name is required"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Name"
              name="name"
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <TextField
              value={charity.mission}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Mission"
              name="mission"
              onChange={(e) => handleChange("mission", e.target.value)}
            />
            <TextField
              value={charity.cause}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Cause"
              name="cause"
              onChange={(e) => handleChange("cause", e.target.value)}
            />
            <TextField
              value={charity.organizationUrl}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Organization Url"
              name="organizationUrl"
              onChange={(e) => handleChange("organizationUrl", e.target.value)}
            />
            <TextField
              value={charity.street}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Street line 1"
              name="street"
              onChange={(e) => handleChange("street", e.target.value)}
            />
            <TextField
              value={charity.street2}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Street line 2"
              name="street2"
              onChange={(e) => handleChange("street2", e.target.value)}
            />
            <TextField
              value={charity.city}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="City"
              name="city"
              onChange={(e) => handleChange("city", e.target.value)}
            />
            <TextField
              value={charity.state}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="State"
              name="state"
              onChange={(e) => handleChange("state", e.target.value)}
            />
            <TextField
              value={charity.zip}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Zip"
              name="zip"
              onChange={(e) => handleChange("zip", e.target.value)}
            />

            <TextField
              value={charity.country}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="Country"
              name="country"
              onChange={(e) => handleChange("country", e.target.value)}
            />
          </>


        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            variant="contained"
            onClick={() => {
              if ((!charity.ein || !charity.name) && data.value === "APPROVED") {
                setError(true);
              } else {
                mutate(
                  {
                    resource: "recommended-charity",
                    values:
                      data.value === "APPROVED"
                        ? {
                          status: data.value,
                          charity
                        }
                        : {
                          status: data.value,
                        },
                    id: `${data.recommendedCharity?.recommendedCharityId as any
                      }`,

                    errorNotification: (error) => {
                      return {
                        message: error?.message as string,
                        description: "Error",
                        type: "error",
                      };
                    },
                  },
                  {
                    onSuccess: () => {
                      setError(false);
                      close(false);
                      if (data.value === "APPROVED") {
                        navigate("/recommended-charities/status/approved");

                      }
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
              setError(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
