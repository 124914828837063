import React, { useState } from "react";
import { List, DateField } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { moneyFormat } from "utils/money-format";
import {
  ITransferTransaction,
  TRANSFER_TRANSACTION_TYPE_ENUM,
} from "interfaces/transfer-transaction";
import { EvaluateTransferTransactionModal } from "components/modal/evaluate-transfer-transaction";
import { InfiniteList } from "components/list/infinity-list";
export const PendingTransferTransactionList: React.FC<
  IResourceComponentsProps
> = () => {
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    transferTransaction: ITransferTransaction | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED";
  }>({ transferTransaction: undefined, value: "PENDING" });

  return (
    <>
      <List title=" Pending Transfer Transaction List">
        <InfiniteList<ITransferTransaction>
          endpoint="transfer-transactions"
          limit={10}
          isTable={true}
          columns={[
            {
              field: "transferId",
              header: "ID",
            },
            {
              field: "senderFund",
              header: "From",
              renderCell: function render({ senderFund }) {
                return senderFund.fundAccountName;
              },
            },
            {
              field: "receiverFund",
              header: "To",
              renderCell: function render({ receiverFund }) {
                return receiverFund.fundAccountName;
              },
            },
            {
              field: "user",
              header: "Advisor",
              renderCell: function render({ user }) {
                return `${user.firstName} ${user.lastName}`;
              },
            },
            {
              field: "transferType",
              header: "Type",
            },
            {
              field: "reference",
              header: "Reference",
            },

            {
              field: "amount",
              header: "Value",
              renderCell: function render(transaction) {
                if (
                  transaction.transferType ===
                  TRANSFER_TRANSACTION_TYPE_ENUM.INVESTMENTS
                ) {
                  return <>{transaction.amount} unit(s)</>;
                } else {
                  return <>${moneyFormat(transaction.amount)}</>;
                }
              },
            },
            {
              field: "status",
              header: "Status",
            },
            {
              field: "executionDate",
              header: "Date",
              renderCell: function render({ executionDate }) {
                return <DateField value={executionDate} />;
              },
            },
            {
              header: "Actions",

              renderCell: function render(transaction) {
                return (
                  <>
                    {" "}
                    <IconButton
                      onClick={() => {
                        setDecision({
                          transferTransaction: transaction,
                          value: "APPROVED",
                        });
                        setOpen(true);
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDecision({
                          transferTransaction: transaction,
                          value: "REJECTED",
                        });
                        setOpen(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                );
              },
            },
          ]}
          additionalFilters={[
            {
              field: "status",
              operator: "eq",
              value: "PENDING",
            },
          ]}
          tableProps={{
            stickyHeader: true,
            headerBgColor: "grey.200",
            responsive: true,
          }}
          emptyMessage={`No transaction found!`}
        />
      </List>
      {
        <EvaluateTransferTransactionModal
          visible={open}
          close={setOpen}
          data={decision}
        />
      }
    </>
  );
};
