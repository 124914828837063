import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List, ShowButton } from "@refinedev/mui";
import { IInvestmentRequest } from "interfaces/investment-requests";
import { moneyFormat } from "utils/money-format";
import { useNavigate } from "react-router-dom";
import { InfiniteList } from "components/list/infinity-list";

export const InvestmentRequestsPendingList: React.FC<
  IResourceComponentsProps
> = () => {
  const navigate = useNavigate();
  return (
    <List title="Pending Investment Requests">
      <InfiniteList<IInvestmentRequest>
        endpoint="investments-requests"
        limit={10}
        isTable={true}
        columns={[
          {
            field: "investmentRequestId",
            header: "ID",
          },
          {
            field: "investment",
            header: "Investment",
            renderCell: function render({ investment }) {
              return investment.ticker;
            },
          },
          {
            field: "fund",
            header: "Fund Name",
            width: 200,
            renderCell: function render({ fund }) {
              return fund.fundAccountName;
            },
          },
          {
            field: "user",
            header: "Investor",
            width: 200,
            renderCell: function render({ user }) {
              return `${user.firstName} ${user.lastName}`;
            },
          },
          {
            field: "type",
            header: "type",
          },

          {
            field: "requestedAmount",
            header: "Requested Amount",
            renderCell: function render({ requestedAmount }) {
              return <>${moneyFormat(requestedAmount, 2)}</>;
            },
          },
          {
            field: "appliedAmount",
            header: "Applied Amount",
            renderCell: function render({ appliedAmount }) {
              return <>${moneyFormat(appliedAmount, 2)}</>;
            },
          },
          {
            field: "requestedUnits",
            header: "Requested Units",
            renderCell: function render({ requestedUnits }) {
              return <>{moneyFormat(requestedUnits, 4)} unit(s)</>;
            },
          },

          {
            field: "appliedUnits",
            header: "Applied Units",
            renderCell: function render({ appliedUnits }) {
              return <>{moneyFormat(appliedUnits, 4)} unit(s)</>;
            },
          },
          {
            header: "Actions",
            renderCell: function render({ investmentRequestId }) {
              return (
                <ShowButton
                  hideText
                  onClick={() =>
                    navigate(
                      `/investment-requests/pending/${investmentRequestId}`
                    )
                  }
                />
              );
            },
          },
        ]}
        additionalFilters={[
          {
            field: "filter[status]",
            operator: "eq",
            value: "PENDING",
          },
          {
            field: "filter[transfer]",
            operator: "eq",
            value: "false",
          },
        ]}
        tableProps={{
          stickyHeader: true,
          headerBgColor: "grey.200",
          responsive: true,
        }}
        emptyMessage={`No investment request found!`}
      />
    </List>
  );
};
