import React, { useEffect, useState } from "react";
import { List } from "@refinedev/mui";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import LaunchIcon from "@mui/icons-material/Launch";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { CustomCard } from "components/card/CharityCard";
import SearchIcon from "@mui/icons-material/Search";
import { InView } from "react-intersection-observer";
import ClearIcon from "@mui/icons-material/Clear";
import { IRecommendedCharity } from "interfaces/recommended-charity";

export const RecommendedCharities: React.FC<IResourceComponentsProps> = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [charitiesData, setCharitiesData] = useState<IRecommendedCharity[]>([]);
  const [limit] = useState(12);
  const {
    data: charities,
    isSuccess: isGetCharitiesSuccess,
    isFetching: isGetCharitiesLoading,
  } = useCustom({
    url: `recommended-charity`,
    method: "get",
    config: {
      query: {
        searchTerm: searchTerm,
        limit: limit,
        offset: offset,
        "orderBy[updatedAt]": "DESC",
        "filter[status]": "APPROVED",
      },
    },
  });

  useEffect(() => {
    if (charities) {
      if (
        (charities.data as PaginatedEntityList<IRecommendedCharity>)?.pagination
          ?.offset === 0
      ) {
        setCharitiesData(
          (charities.data as PaginatedEntityList<IRecommendedCharity>).data
        );
      } else {
        setCharitiesData([
          ...charitiesData,
          ...(charities.data as PaginatedEntityList<IRecommendedCharity>).data,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charities]);

  const searchByKeyword = (event: any) => {
    if (event.key === "Enter") {
      clear();
      setSearchTerm(event.target.value);
    }
  };
  const reset = () => {
    setSearch("");
    setSearchTerm("");
    clear();
  };

  const clear = () => {
    setCharitiesData([]);
    setOffset(0);
  };
  const fetchData = (inView: boolean) => {
    if (inView && charities?.data?.pagination.nextOffset)
      setOffset(charities?.data?.pagination.nextOffset);
  };

  return (
    <>
      <List
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          },
        }}
        title="Recommended Charities"
      >
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12} sm={6} sx={{ textAlign: "start" }}>
            <FormControl variant="outlined" fullWidth>
              <FilledInput
                className="search"
                onKeyDown={(event) => {
                  searchByKeyword(event);
                }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                value={search}
                placeholder="Type keyword or name"
                size="small"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  search !== "" && (
                    <InputAdornment position="end">
                      <IconButton onClick={reset} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            {isGetCharitiesSuccess && charitiesData.length > 0
              ? charitiesData.map((charity) => (
                <Grid key={charity.ein} item xs={12} md={6}>
                  <CustomCard>
                    <CardContent sx={{ "&:last-child": { pb: 5 } }}>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems={"center"}
                        >
                          <Typography
                            variant="smallCopy"
                            sx={{
                              color: "neutral.main",
                            }}
                          >
                            {charity.ein}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box sx={{ minHeight: "120px" }}>
                        <Typography
                          gutterBottom
                          sx={{
                            color: "neutral.main",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            height: "54px",
                          }}
                          variant="subtitle1"
                        >
                          {charity.charityName}
                        </Typography>
                        <Typography
                          paragraph
                          sx={{
                            textTransform: "lowercase",
                            color: "neutral.main",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                          }}
                          variant="smallCopyBold"
                        >
                          {charity.additionalInformation}
                        </Typography>
                      </Box>
                      <Typography
                        paragraph
                        sx={{
                          color: "neutral.main",
                        }}
                        variant="smallCopyBold"
                      >
                        {charity.contactEmail}
                        <br></br> {charity.contactPhone}
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography
                          sx={{
                            color: "neutral.main",
                          }}
                          variant="bodyCopyBold"
                        >
                          Added by: {charity.user.firstName} {charity.user.lastName}
                        </Typography>

                        <Button
                          target="_blank"
                          href={`https://${charity.websiteLink}`}
                          startIcon={<LaunchIcon />}
                          variant="text"
                          color="primary"
                          className="charites-more-info"
                        >
                          More info
                        </Button>
                      </Stack>
                    </CardContent>
                  </CustomCard>
                </Grid>
              ))
              : !isGetCharitiesLoading && (
                <Typography
                  sx={{
                    color: "neutral.main",
                    pl: 3,
                  }}
                  variant="smallCopyBold"
                >
                  No charity found.
                </Typography>
              )}
            {isGetCharitiesLoading ? (
              <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            ) : charities?.data?.pagination.hasNext ? (
              <InView
                as="div"
                onChange={(inView) => fetchData(inView)}
              ></InView>
            ) : null}
          </Grid>
        </Box>
      </List>
    </>
  );
};
